import React from 'react';
import PropTypes from 'prop-types';

const TermsAgreementButton = ({ onClick }) => (
  <div className="p-2 flex-shrink-1">
    <button
      type="button"
      className="btn btn-secondary"
      onClick={() => onClick()}
    >
      Entendido
    </button>
  </div>
);

TermsAgreementButton.defaultProps = {
  onClick: () => {}
};

TermsAgreementButton.propTypes = {
  onClick: PropTypes.func,
};

export default TermsAgreementButton;
