import React, { useRef } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useSpring, animated } from 'react-spring';
import { Element } from 'react-scroll';

// Styles
import './Home.scss';

// Images
import homeBg from '../../assets/img/home-background.svg';
import logoWhite from '../../assets/img/logo-white.svg';
import logo from '../../assets/img/logo.svg';
import downArrow from '../../assets/img/flecha-down.svg';
import aloneWoman from '../../assets/img/alone-woman.svg';
import aloneFloor from '../../assets/img/alone-floor.svg';
import womanInBook from '../../assets/img/book-woman.svg';

export const Home = () => {

  // Section refs
  const reasonSection = useRef(null);
  const welcomeSection = useRef(null);

  const [{ y }, setJump] = useSpring(() => ({ y: 0 }));

  return (
    <Element name="home">
      <section
        id="home"
        className="home-main-section"
      >
        <img
          className="home-main-bg"
          alt="Background principal"
          src={homeBg}
        />
        <Row className="main-row">
          <img
            src={logoWhite}
            className="home-main-logo mx-auto ml-lg-5 d-block"
            alt="Espiral Punto Cero"
          />
          <Col
            xs="12"
            className="text-center"
          >
            <h1 className="paragraph-text text-white question-header mb-0">
              ¿Cómo saber si requiero
            </h1>
            <h1 className="paragraph-text text-white question-header mb-2">ayuda psicológica?</h1>
            <animated.img
              src={downArrow}
              onMouseEnter={() => setJump({ y: 10 })}
              onMouseLeave={() => setJump({ y: 0 })}
              className="down-arrow"
              style={{
                position: 'relative',
                bottom: 'auto',
                transform: y.interpolate((yInt) => `translate3d(0, ${yInt}px, 0)`),
              }}
              onClick={() => reasonSection.current.scrollIntoView({ block: 'end', behavior: 'smooth' })}
              alt="Siguiente sección"
            />
          </Col>
        </Row>
      </section>

      <section
        ref={reasonSection}
        className="reasons-section"
      >
        {/* Rectangles big resolution */}
        <div className="reason-rectangle-section d-none d-xl-inline">
          <div className="reason-rectangle" />
          <div className="reason-rectangle" />
          <div className="reason-rectangle" />
          <div className="reason-rectangle" />
        </div>
        {/* Rectangles mobile */}
        <Row className="reason-rectangle-section d-xl-none">
          <Col
            xs={12}
            md={6}
            className="reason-rectangle text-center"
          />
          <Col
            xs={12}
            md={6}
            className="reason-rectangle text-center"
          />
          <Col
            xs={12}
            md={6}
            className="reason-rectangle text-center"
          />
          <Col
            xs={12}
            md={6}
            className="reason-rectangle text-center"
          />
        </Row>
        <img
          alt="Mujer pensativa"
          src={aloneWoman}
          className="alone-woman"
        />
        <img
          className="alone-floor"
          alt="Alone floor"
          src={aloneFloor}
        />
      </section>

      <section
        ref={welcomeSection}
        className="welcome-section"
      >
        <Col className="row mx-0">
          <Col
            md="5"
            lg="6"
            className="d-none d-md-inline"
          >
            <img
              alt="Mujer en libro"
              src={womanInBook}
              className="book-woman"
            />
          </Col>
          <Col
            xs="12"
            md="7"
            lg="6"
            className="welcome-container mx-0 px-0"
          >
            <h1 className="main-title text-white text-center">¡Bienvenida (o)!</h1>
            <div className="welcome-text">
              <p className="col paragraph-text">
                Nos encanta tenerte en este espacio de reflexión; en
              </p>
              <img
                src={logo}
                className="welcome-logo mx-auto d-block"
                alt="Espiral Punto Cero"
              />
              <p className="col paragraph-text">
                creemos sin duda que el cambio personal se trabaja
                <br className="d-none d-xl-block" />
                <span> </span>
                &quot;sin pausas pero sin prisas&quot;.
              </p>
              <br />
              <p className="col paragraph-text">
                Sé cuántas dudas puedes tener antes de decidir iniciar
                <br className="d-none d-xl-block" />
                <span> </span>
                un proceso de psicoterapia, ¡créeme! yo también lo viví
                <br className="d-none d-xl-block" />
                <span> </span>
                (y lo vivo) y por eso, quiero compartir contigo
                <br className="d-none d-xl-block" />
                <span> </span>
                acompañamiento, información y herramientas
                <br className="d-none d-xl-block" />
                <span> </span>
                que te permitan:
              </p>
              <br />
              <ul>
                <li className="col paragraph-text">
                  Mejorar tus relaciones de pareja y familia
                </li>
                <li className="col paragraph-text">
                  Aprender a reconocer y gestionar tus emociones
                </li>
                <li className="col paragraph-text">
                  Superar estados psicológicos de ansiedad y depresión
                </li>
              </ul>
              <p className="paragraph-text text-center welcome-footer">
                ¿Me dejas acompañarte?
              </p>
            </div>
          </Col>
        </Col>
        <img
          alt="Mujer en libro"
          src={womanInBook}
          className="book-woman d-sm-inline d-md-none px-2"
        />
      </section>
    </Element>
  );
};
