import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

// Component
import ConfirmationPopupContent from './ConfirmationPopupContent';

const ConfirmationPopup = ({ show, onHide }) => (
  <Modal
    centered
    show={show}
    onHide={onHide}
    aria-labelledby="confirmacion-modal"
  >
    <ConfirmationPopupContent onHide={onHide} />
  </Modal>
);

ConfirmationPopup.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired
};

export default ConfirmationPopup;
