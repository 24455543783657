import React, { useState } from 'react';
import Switch from 'react-bootstrap/Switch';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { useTrail, animated as a, useSpring } from 'react-spring';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { useMediaQuery } from 'react-responsive';
import { Nav } from 'react-bootstrap';
import SVG from 'react-inlinesvg';

// Images
import facebook from '../assets/img/facebook.svg';
import instagram from '../assets/img/instagram.svg';
import menuButton from '../assets/img/menu-boton.svg';
import hamburger from '../assets/img/bars-solid.svg';

// Styles
import './Menu.scss';

// Components
import { FAQ } from '../components/faq/FAQ';
import { Main } from '../components/main/Main';
import Workshop from '../components/workshop/Workshop';
import Privacy from '../components/privacy/Privacy';
import MenuItem from './MenuItem';
import Sidebar from '../components/sidebar/Sidebar';

export const Menu = () => {
  const [expanded, setExpanded] = useState(false);
  const [menuAnimation, showMenuAnimation] = useState(false);
  const [divWrapper, setDivWrapper] = useState('none');
  const [normalMenu, showNormalMenu] = useState(true);

  useScrollPosition(
    ({ currPos }) => {
      const isOrigin = currPos.y === 0;
      showNormalMenu(isOrigin);
    },
    [normalMenu],
    false,
    false,
    300
  );

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 991.98px)' });

  const config = { mass: 5, tension: 2000, friction: 150 };

  const displayMenuWrapper = (display) => {
    if (display) {
      setTimeout(() => setDivWrapper('none'), 200);
    } else {
      setDivWrapper('flex');
    }
  };

  const handleAnimation = () => {
    setExpanded(false);
    displayMenuWrapper(menuAnimation);
    showMenuAnimation((a) => !a);
  };

  const handleToggleExpanded = () => {
    setExpanded(!expanded);
  };

  const menuItems = (width, flat) => [
    <Nav.Link
      key="instagram"
      target="blank"
      href="https://www.instagram.com/epiralpuntocero/"
      style={{ width }}
      className="social-link"
    >
      <SVG
        src={instagram}
        className="social-icons"
        alt="Enlace a Instagram de Espiral Punto Cero"
      />
    </Nav.Link>,
    <Nav.Link
      key="facebook"
      target="blank"
      href="https://www.facebook.com/EspiralPuntocero/"
      style={{ width }}
      className="social-link"
    >
      <SVG
        src={facebook}
        className="social-icons"
        alt="Enlace a Facebook de Espiral Punto Cero"
      />
    </Nav.Link>,
    <MenuItem
      flat={flat}
      smooth={true}
      spy={true}
      hashSpy={true}
      id="home-link"
      key="home-link"
      style={{ ...width, width: '80px', textAlign: 'center' }}
      activeClass="active-link"
      className="nav-link"
      onClick={() => handleAnimation()}
      to="home"
      href="/#home"
      content={
        <span>Inicio</span>
      }
    />,
    <MenuItem
      flat={flat}
      smooth={true}
      spy={true}
      hashSpy={true}
      id="about-link"
      key="about-link"
      style={{ ...width, width: '130px', textAlign: 'center' }}
      activeClass="active-link"
      className="nav-link"
      onClick={() => handleAnimation()}
      to="about"
      href="/#about"
      content={
        <span>Acerca de Mi</span>
      }
    />,
    <MenuItem
      flat={flat}
      smooth={true}
      spy={true}
      hashSpy={true}
      id="services-link"
      key="services-link"
      style={{ ...width, width: '100px', textAlign: 'center' }}
      activeClass="active-link"
      className="nav-link"
      onClick={() => handleAnimation()}
      to="services"
      href="/#services"
      content={
        <span>Servicios</span>
      }
    />,
    <MenuItem
      flat={flat}
      smooth={true}
      spy={true}
      hashSpy={true}
      id="resources-link"
      key="resources-link"
      style={{ ...width, width: '150px', textAlign: 'center' }}
      activeClass="active-link"
      className="nav-link"
      onClick={() => handleAnimation()}
      to="resources"
      href="/#resources"
      content={
        <span>Otros Recursos</span>
      }
    />,
    <Nav.Link
      id="blog-link"
      key="blog-link"
      style={{ ...width, width: '100px', textAlign: 'center' }}
      href="https://blog.espiralpuntocero.com"
      target="blank"
    >
      <span>Blog</span>
    </Nav.Link>,
    <MenuItem
      flat={flat}
      smooth={true}
      spy={true}
      hashSpy={true}
      id="contact-link"
      key="contact-link"
      style={{ ...width, width: '100px', textAlign: 'center' }}
      activeClass="active-link"
      className="nav-link"
      onClick={() => handleAnimation()}
      to="contact"
      href="/#contact"
      content={
        <span>Contacto</span>
      }
    />,
    <Nav.Link
      id="faq-link"
      key="faq-link"
      style={{ ...width, width: '100px', textAlign: 'center' }}
      className="nav-link"
      onClick={() => handleAnimation()}
      href="/faq"
    >
      <span>FAQ</span>
    </Nav.Link>
  ];

  const { opacity, x } = useSpring({
    config,
    opacity: menuAnimation ? 1 : 0,
    x: menuAnimation ? 0 : 1.5,
    from: { opacity: 0, x: 0, width: 0 }
  });

  const trail = useTrail(menuItems(0).length, {
    config,
    opacity: menuAnimation ? 1 : 0,
    x: menuAnimation ? -20 : 20,
    width: menuAnimation ? 80 : 0,
    from: { opacity: 0, x: 20, width: 0 }
  });

  const buildScrolledMenu = (flat) => (
    <a.div
      style={{
        opacity,
        display: divWrapper,
        transform: x.interpolate((xInt) => `translate3d(${xInt}vw, 0, 0)`)
      }}
      className="justify-content-end fixed-top black-nav nav"
    >
      {trail.map(({ x, width, ...rest }, index) => (
        <a.div
          id={index}
          key={index}
          style={{
            ...rest,
            transform: x.interpolate((xInt) => `translate3d(${xInt}px, 0, 0)`)
          }}
        >
          {menuItems(width, flat)[index]}
        </a.div>
      ))}
    </a.div>
  );

  const buildNoScrolledMenu = (flat) => (
    <a.div className="justify-content-end fixed-top nav pt-4 no-scrolled-nav">
      {menuItems(0, flat).map((item, index) => (
        <a.div id={index} key={index}>
          {item}
        </a.div>
      ))}
    </a.div>
  );

  const buildSidebar = (flat) => (
    <>
      <div
        role="button"
        className="hamburger-button"
      >
        <img
          role="presentation"
          src={hamburger}
          alt="Menu"
          onClick={handleToggleExpanded}
        />
      </div>
      <Sidebar
        show={expanded}
        onClose={handleToggleExpanded}
        items={menuItems(0, flat)}
      />
    </>
  );

  const buildMenu = (flat) => {
    if (!isTabletOrMobile) {
      if (normalMenu) {
        return buildNoScrolledMenu(flat);
      }
      return buildScrolledMenu(flat);
    }
    return buildSidebar(flat);

  };

  return (
    <>
      <SVG
        src={menuButton}
        style={{ display: normalMenu ? 'none' : 'flex' }}
        className="menu-button"
        alt="Menú retráctil"
        onClick={handleAnimation}
      />
      <Router>
        <Switch className="p-0">
          <Route exact path="/">
            { buildMenu(false) }
            <Main />
          </Route>
        </Switch>
        <Switch className="p-0">
          <Route
            path="/talleres"
          >
            { buildMenu(true) }
            <Workshop />
          </Route>
          <Route
            path="/faq"
          >
            { buildMenu(true) }
            <FAQ />
          </Route>
          <Route
            path="/privacy"
          >
            { buildMenu(true) }
            <Privacy />
          </Route>
        </Switch>
      </Router>
    </>
  );
};
