import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Modal } from 'react-bootstrap';

// API
import { sendMail } from '../../api/Mail';

// Styles
import './Popup.scss';

// Images
import img from '../../assets/img/popup-img.svg';
import ConfirmationPopup from './ConfirmationPopup';

export const PopUp = ({ show, handleClose }) => {

  const [validated, setValidated] = useState(false);
  const [confirmation, showConfirmation] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [reason, setReason] = useState('');

  const handleNameChange = (event) => setName(event.target.value);
  const handleEmailChange = (event) => setEmail(event.target.value);
  const handlePhoneChange = (event) => setPhone(event.target.value);
  const handleReasonChange = (event) => setReason(event.target.value);

  const resetFields = () => {
    setName('');
    setEmail('');
    setPhone('');
    setReason('');
  };

  const handleHide = () => {
    resetFields();
    handleClose();
  };

  const handleSend = () => {
    if (
      name.length >= 3
      && email.length
      && (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(email))
      && reason.length >= 3
    ) {
      sendMail({
        name, email, phone, reason
      });
      showConfirmation(true);
      handleHide();
    } else {
      setValidated(true);
    }

  };

  return (
    <>
      <Modal
        centered
        size="lg"
        show={show}
        onHide={handleHide}
        className="popup-contacto"
        aria-labelledby="contacto-modal"
      >
        <section id="popup-section">
          <Modal.Header className="p-0" closeButton />
          <Form
            className="needs-validation"
            validated={validated}
            noValidate
          >
            <Form.Row>
              <Col
                xs="12"
                lg="6"
              >
                <p className="paragraph-text popup-paragraph text-white">
                  Déjame tus datos y en breve
                  <br className="d-none d-xl-block" />
                  <span> </span>
                  me comunicaré contigo para
                  <br className="d-none d-xl-block" />
                  <span> </span>
                  agendar tu primera sesión a
                  <br className="d-none d-xl-block" />
                  <span> </span>
                  un costo preferencial
                </p>
                <br />
                <div className="form-group">
                  <label htmlFor="description">
                    <div className="pb-2">
                      <span className="paragraph-text popup-paragraph text-white">
                        Describe brevemente el motivo
                        <br className="d-none d-xl-block" />
                        <span> </span>
                        de tu consulta
                      </span>
                    </div>
                    <textarea
                      id="description"
                      className="form-control"
                      value={reason}
                      onChange={handleReasonChange}
                      rows="8"
                      cols="30"
                      maxLength="500"
                      minLength="3"
                      aria-label="Razon"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Estos datos son necesarios para ponerte en contacto :)
                    </Form.Control.Feedback>
                  </label>
                </div>
                <span className="popup-footer paragraph-text popup-paragraph text-white">
                  También puedes enviar un email a
                  <br className="d-none d-xl-block" />
                  <span> </span>
                  <a href="mailto:espiral.cero.psicoterapia@gmail.com">espiral.cero.psicoterapia@gmail.com</a>
                </span>
              </Col>
              <Col>
                <div className="form-group col row mx-0 p-0">
                  <div className="col-12 col-lg-3 pt-2">
                    <span className="paragraph-text popup-paragraph text-white">Nombre</span>
                  </div>
                  <div className="col-12 col-lg-9">
                    <label htmlFor="nombre">
                      <input
                        id="nombre"
                        type="text"
                        className="form-control"
                        value={name}
                        onChange={handleNameChange}
                        minLength="3"
                        aria-label="Nombre"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Estos datos son necesarios para ponerte en contacto :)
                      </Form.Control.Feedback>
                    </label>
                  </div>
                </div>
                <div className="form-group col row mx-0 p-0">
                  <div className="col-12 col-lg-3 pt-2">
                    <span className="paragraph-text popup-paragraph text-white">Email</span>
                  </div>
                  <div className="col-12 col-lg-9">
                    <label htmlFor="email">
                      <input
                        type="email"
                        pattern="^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$"
                        className="form-control"
                        value={email}
                        onChange={handleEmailChange}
                        aria-label="Email"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Estos datos son necesarios para ponerte en contacto :)
                      </Form.Control.Feedback>
                    </label>
                  </div>
                </div>
                <div className="form-group col row mx-0 p-0">
                  <div className="col-12 col-lg-3 pt-2">
                    <span className="paragraph-text popup-paragraph text-white">Teléfono</span>
                  </div>
                  <div className="col-12 col-lg-9">
                    <label htmlFor="telefono">
                      <input
                        type="text"
                        className="form-control"
                        value={phone}
                        onChange={handlePhoneChange}
                        aria-label="Telefono"
                      />
                    </label>
                  </div>
                </div>
                <div className="send-button text-center row align-items-center">
                  <Col
                    className="cursor-pointer"
                  >
                    <button
                      type="button"
                      onClick={handleSend}
                      className="send-text text-primary"
                    >
                      <b>Enviar</b>
                    </button>
                  </Col>
                </div>
                <img
                  src={img}
                  alt="Contáctame"
                  className="popup-image"
                />
              </Col>
            </Form.Row>
          </Form>
        </section>
      </Modal>
      <ConfirmationPopup show={confirmation} onHide={() => showConfirmation(false)} />
    </>
  );
};

PopUp.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};
