/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React from 'react';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-scroll';

const MenuItem = ({
  flat,
  smooth,
  spy,
  hashSpy,
  id,
  style,
  activeClass,
  className,
  content,
  to,
  href,
  offset,
  delay,
  onClick
}) => (
  flat ? (
    <Nav.Link {...{
      id,
      style,
      className,
      href
    }}
    >
      {content}
    </Nav.Link>
  ) : (
    <Link
      duration={100}
      {...
        {
          smooth,
          spy,
          hashSpy,
          id,
          style,
          activeClass,
          className,
          to,
          offset,
          delay,
          onClick
        }}
    >
      {content}
    </Link>
  )
);

MenuItem.defaultProps = {
  flat: false,
  smooth: false,
  spy: false,
  hashSpy: false,
  id: '',
  style: null,
  activeClass: '',
  className: '',
  content: null,
  to: '',
  href: '',
  offset: 0,
  delay: 0,
  onClick: () => {},
};

MenuItem.propTypes = {
  flat: PropTypes.bool,
  smooth: PropTypes.bool,
  spy: PropTypes.bool,
  hashSpy: PropTypes.bool,
  id: PropTypes.string,
  style: PropTypes.object,
  activeClass: PropTypes.string,
  className: PropTypes.string,
  content: PropTypes.object,
  to: PropTypes.string,
  href: PropTypes.string,
  offset: PropTypes.number,
  delay: PropTypes.number,
  onClick: PropTypes.func,
};

export default MenuItem;
