import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import GoogleMapReact from 'google-map-react';

// Styles
import './Contact.scss';

// Images
import mainImage from '../../assets/img/contact-bg.svg';
import mapPin from '../../assets/img/map-pin.svg';
import fotoCircular from '../../assets/img/Eunice-foto-circular.png';

export const Contact = () => {

  const coords = {
    center: {
      lat: 19.458617,
      lng: -99.177995
    },
    zoom: 16
  };

  const MapPin = () => (
    <div>
      <img
        src={mapPin}
        className="map-pin"
        alt="Pin de ubicación de consultorio"
      />
    </div>
  );

  return (
    <section
      id="contact-section"
      className="mr-lg-6"
    >
      <Row>
        <Col
          id="contact"
          xs="12"
          md="6"
          className="text-center mt-3"
        >
          <h1 className="mt-4 ml-lg-5 main-title contact-header">Contáctame</h1>
          <Card className="contact-card mt-4">
            <Card.Body>

              <img
                src={fotoCircular}
                className="circle-photo"
                alt="Eunice Hinojosa"
              />

              <h2 className="contact-card-title">Psicóloga y Psicoterapeuta</h2>
              <h3 className="contact-card-title">Eunice Hinojosa Olvera</h3>
              <a href="mailto:espiral.cero.psicoterapia@gmail.com" className="contact-card-email">espiral.cero.psicoterapia@gmail.com</a>
            </Card.Body>
          </Card>
        </Col>
        <Col
          xs="12"
          md="6"
        >
          <div className="contact-map">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: 'AIzaSyAf4MVYMxQb-SvFYfdRv4XkmSXQD1zG8Hw'
              }}
              defaultCenter={coords.center}
              defaultZoom={coords.zoom}
            >
              <MapPin lat={19.458617} lng={-99.177995} />
            </GoogleMapReact>

          </div>
          <img
            src={mainImage}
            alt="Contacto"
            className="contact-image"
          />
        </Col>
      </Row>
    </section>
  );
};
