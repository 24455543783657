import React from 'react';
import PropTypes from 'prop-types';
import { ProSidebar } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';

import './Sidebar.scss';

import logo from '../../assets/img/logo-white.svg';

const Sidebar = ({ show, onClose, items }) => (
  <>
    <ProSidebar
      breakPoint="lg"
      toggled={show}
      onToggle={() => onClose()}
    >
      <a
        href="#home"
        className="text-center pt-3"
      >
        <img
          src={logo}
          alt="Espiral Punto Cero"
          className="logo-nav-mobile"
        />
      </a>
      { items.map(item => item) }
    </ProSidebar>
  </>
);

Sidebar.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default Sidebar;
