import React from 'react';
import PropTypes from 'prop-types';

// Components
import TermsAgreementButton from './TermsAgreementButton';
import TermsAgreementText from './TermsAgreementText';

const TermsAgreementWrapper = ({ onClick }) => (
  <div
    id="terms-conditions"
    className="alert alert-dark m-0 fixed-bottom d-flex"
  >
    <TermsAgreementText />
    <TermsAgreementButton onClick={onClick} />
  </div>
);

TermsAgreementWrapper.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default TermsAgreementWrapper;
