import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Element } from 'react-scroll';

// Styles
import './About.scss';

// Images
import mainEunice from '../../assets/img/about-eunice.png';
import logo from '../../assets/img/logo.svg';
import aboutBg from '../../assets/img/about-background.svg';
import aboutPhotoBg from '../../assets/img/about-photo-background.svg';
import yellowBox from '../../assets/img/yellow-balloon.svg';

// Components
import { PopUp } from '../popup/Popup';

export const About = () => {

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <Element
      name="about"
    >
      <section
        id="about"
        className="main-about-section limit-max-container"
      >
        <Row className="about-section-text">
          <Col
            xs={12}
            md={6}
            className="main-col mt-3 text-center"
          >
            <img
              src={aboutBg}
              className="about-bg"
              alt="Acerca de mi background"
            />
            <h1 className="main-title mb-4">Acerca de Mi</h1>
            <p className="paragraph-text">
              Soy Eunice Hinojosa
              <br />
              ¡Ser testigo de los procesos de cambio y reflexión
              <br />
              de las personas es mi pasión!
            </p>
            <p className="col paragraph-text">
              Acompañar la transformación de las personas hacia
              <br className="d-none d-xl-block" />
              <span> </span>
              una vida mucho más consciente, plena y psicológicamente
              <br className="d-none d-xl-block" />
              <span> </span>
              saludable, como dice Viktor Frankl, me llena de sentido.
              <br className="d-none d-xl-block" />
              <span> </span>
              Soy Psicóloga por la Universidad del Valle de
              México
              <br className="d-none d-xl-block" />
              <span> </span>
              (UVM) y Psicoterapeuta por la Asociación Mexicana
              <br className="d-none d-xl-block" />
              <span> </span>
              de Alternativas en Psicología (AMPSI) con una
              <br className="d-none d-xl-block" />
              <span> </span>
              especialidad en Psicología clínica.
              <br className="d-none d-xl-block" />
              <span> </span>
              Mis bases de trabajo son la Psicología de la praxis
              <br className="d-none d-xl-block" />
              <span> </span>
              y Terapia familiar.
            </p>
            <p className="col paragraph-text">
              Dirijo
              <img
                src={logo}
                className="text-logo"
                alt="Espiral Punto Cero"
              />
              ,
              <br />
              proyecto con el que busco crear un espacio
              <br className="d-none d-xl-block" />
              <span> </span>
              para hablar de salud psicológica como
              <br className="d-none d-xl-block" />
              <span> </span>
              siempre ha debido ser
              <br className="d-none d-xl-block" />
              <span> </span>
              ¡con total normalidad
              y sin tabúes!
            </p>
            {/* Orange box big screen */}
            <div
              role="button"
              className="d-none d-md-inline"
            >
              <img
                role="presentation"
                src={yellowBox}
                onClick={handleShow}
                className="yellow-button"
                alt="Imagen principal de Eunice Hinojosa"
              />
            </div>
            {/* Orange box mobile */}
            <div
              role="button"
              className="d-sm-inline d-md-none"
            >
              <img
                role="presentation"
                src={yellowBox}
                onClick={handleShow}
                className="yellow-button"
                alt="Imagen principal de Eunice Hinojosa"
              />
            </div>
          </Col>
          <Col
            xs="12"
            md="6"
          >
            <img
              src={aboutPhotoBg}
              className="about-photo"
              alt="Imagen principal de Eunice Hinojosa"
            />
            <img
              src={mainEunice}
              className="main-photo"
              alt="Imagen principal de Eunice Hinojosa"
            />
          </Col>
        </Row>
        <PopUp
          show={show}
          handleClose={handleClose}
        />
      </section>
    </Element>
  );
};
