import React from 'react';
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// Styles
import './Footer.scss';

// Images
import logo from '../../assets/img/logo-white.svg';
import facebook from '../../assets/img/facebook-white.svg';
import instagram from '../../assets/img/instagram-white.svg';

export const Footer = () => (
  <section id="footer">
    <div className="navbar bg-primary">
      <Col
        xs="12"
        md="8"
        lg="8"
        className="row pr-0 align-items-center"
      >
        <Col
          xs="12"
          md="4"
          lg="3"
        >
          <img
            className="footer-logo text-white"
            alt="Espiral Punto Cero"
            src={logo}
          />
        </Col>

        <Col
          xs="12"
          md="8"
          lg="9"
          className="social-wrapper"
        >
          <a
            href="https://www.instagram.com/epiralpuntocero/"
            target="blank"
          >
            <img
              className="footer-social"
              alt="Instagram"
              src={instagram}
            />
          </a>
          <a
            href="https://www.facebook.com/EspiralPuntocero/"
            target="blank"
          >
            <img
              className="footer-social"
              alt="Facebook"
              src={facebook}
            />
          </a>
        </Col>
      </Col>
      <Col
        xs="12"
        md="4"
        lg="4"
        className="navbar-text footer-text copy-wrapper"
      >
        <p className="paragraph-text text-white">©2020 Derechos Reservados.</p>
        <p className="paragraph-text text-white">Espiral Punto Cero</p>
        <p className="paragraph-text text-white">Arte vectorial por pch.vector</p>
        <p className="paragraph-text text-white">
          Consulta nuestro
          <Link to="privacy"> Aviso de privacidad</Link>
        </p>
      </Col>
    </div>
  </section>
);
