import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';

// Styles
import './Privacy.scss';

// Images
import logo from '../../assets/img/logo.svg';
import privacy from '../../assets/img/privacy-main.svg';

const Privacy = () => {

  useEffect(
    () => {
      setTimeout(() => window.scrollTo(0, 0));
    }, []
  );

  return (
    <div className="container-fluid">
      <img
        className="privacy-logo"
        alt="Logo principal"
        src={logo}
      />
      <Row id="privacy">
        <Col
          xs="12"
          xl="6"
        >
          <div className="privacy-text">
            <h1 className="main-title text-center">Aviso de privacidad</h1>
            <p className="paragraph-text">
              Con fundamento en los artículos 15 y 16 de la Ley Federal de Protección de Datos
              Personales en Posesión de Particulares, hago de su conocimiento que la Psicóloga
              y Psicoterapeuta Eunice Hinojosa Olvera
              {' '}
              <img
                className="text-logo"
                src={logo}
                alt="Espiral punto Cero"
              />
              {' '}
              es responsable de
              recabar sus datos personales, del uso que les de y de la protección de los mismos.
            </p>
            <p className="paragraph-text">
              <ol type="I">
                <li>
                  Su información personal será utilizada para:
                  <p className="paragraph-text">
                    <ul type="a">
                      <li>Proveer los servicios requeridos por el solicitante.</li>
                      <li>Establecer diagnósticos psicológicos y estrategias de intervención.</li>
                      <li>
                        Notificarle sobre nuevos servicios que estén relacionados al servicio
                        transversal solicitado.
                      </li>
                      <li>Comunicarle cambios y/o modificaciones sobre el servicio brindado.</li>
                      <li>
                        Realizar evaluaciones sobre nuestros productos y/o servicios con el
                        objetivo de mejorar la calidad de los mismos.
                      </li>
                      <li>Evaluar la calidad del servicio brindado.</li>
                    </ul>
                  </p>
                  <p className="paragraph-text">
                    De manera adicional, sus datos personales serán utilizados para las siguientes
                    actividades que, si bien no son básicas para el servicio prestado, si nos
                    permiten y facilitan brindarle un mejor servicio:
                  </p>
                  <p className="paragraph-text">
                    <ul type="a">
                      <li>
                        Notificarle sobre nuevos productos y/o servicios que tengan relación con
                        los ya contratados.
                      </li>
                      <li>
                        Elaborar estudios que nos permitan conocer mejor los hábitos de consumo
                        relacionados a los servicios brindados.
                      </li>
                    </ul>
                  </p>
                  <p className="paragraph-text">
                    En caso de que usted no desee que sus datos personales sean tratados para estos
                    fines secundarios, le solicitamos comunicarlo a través de un correo electrónico,
                    con las siguientes indicaciones:
                  </p>
                  <p className="paragraph-text">
                    <ul type="b">
                      <li>
                        Correo:
                        {' '}
                        <a href="mailto:espiral.cero.psicoterapia@gmail.com">espiral.cero.psicoterapia@gmail.com</a>
                      </li>
                      <li>Asunto: USO DE DATOS PERSONALES</li>
                    </ul>
                  </p>
                  <p className="paragraph-text">
                    De ninguna manera la negativa de utilizar sus datos personales para estos fines
                    secundarios, significará la negativa para brindarle servicios solicitados con
                    nosotros.
                  </p>
                </li>

                <li>
                  <p className="paragraph-text">
                    Los datos personales que utilizaremos serán:
                  </p>
                  <p className="paragraph-text">
                    <ul type="a">
                      <li>Datos de identificación</li>
                      <li>Datos de contacto</li>
                      <li>Datos académicos y/o laborales</li>
                    </ul>
                  </p>
                  <p className="paragraph-text">
                    Además de los datos personales mencionados anteriormente, para las finalidades
                    informadas en el presente aviso de privacidad utilizaremos los siguientes
                    datos personales considerados como sensibles, que requieren de especial
                    protección:
                  </p>
                  <p className="paragraph-text">
                    <ul type="a">
                      <li>Datos de salud</li>
                    </ul>
                  </p>
                </li>

                <li>
                  <p className="paragraph-text">
                    Del acceso, la rectificación, la cancelación y la oposición al uso de los
                    datos personales.
                  </p>
                  <p className="paragraph-text">
                    Los denominados derechos ARCO (Acceso, Rectificación, Cancelación y Oposición)
                    le proveen a usted la facultad de:
                  </p>
                  <p className="paragraph-text">
                    <ul type="a">
                      <li>
                        Conocer quién está haciendo uso de sus datos personales y para qué están
                        siendo utilizados
                      </li>
                      <li>
                        Solicitar rectificación de sus datos en caso de que resulten
                        incompletos o inexactos
                      </li>
                      <li>
                        Solicitar la cancelación del uso de los mismos por no ajustarse
                        a las disposiciones
                      </li>
                      <li>
                        Oponerse al uso de sus datos  si es que los mismos fueron obtenidos sin su
                        consentimiento
                      </li>
                    </ul>
                  </p>
                  <p className="paragraph-text">
                    Para el ejercicio de cualquiera de los derechos ARCO, usted deberá presentar
                    la solicitud respectiva a través del siguiente medio:
                  </p>
                  <p className="paragraph-text">
                    <ul type="b">
                      <li>
                        Correo:
                        {' '}
                        <a href="mailto:espiral.cero.psicoterapia@gmail.com">espiral.cero.psicoterapia@gmail.com</a>
                      </li>
                      <li>Asunto: USO DE DATOS PERSONALES</li>
                    </ul>
                  </p>
                  <p className="paragraph-text">
                    Con relación al procedimiento y requisitos para el ejercicio de sus derechos
                    ARCO, le informamos lo siguiente:
                  </p>
                  <p className="paragraph-text">
                    <ul type="a">
                      <li>
                        <p className="paragraph-text">
                          ¿A través de qué medios pueden acreditar su identidad el titular y,
                          en su caso, su representante, así como la personalidad este último?
                        </p>
                        <p className="paragraph-text">
                          Con los documentos que acrediten tu identidad o, en su caso, la
                          representación legal de la persona que realiza la solicitud a
                          su nombre
                        </p>
                      </li>
                      <li>
                        <p className="paragraph-text">
                          ¿Qué información y/o documentación deberá contener la solicitud?
                        </p>
                        <p className="paragraph-text">
                          Su nombre, correo electrónico y domicilio u otro medio para
                          comunicarle la respuesta a su solicitud
                        </p>
                      </li>
                      <li>
                        <p className="paragraph-text">
                          ¿En cuántos días le daremos respuesta a su solicitud?
                        </p>
                        <p className="paragraph-text">
                          15 días hábiles.
                        </p>
                      </li>
                      <li>
                        <p className="paragraph-text">
                          ¿Por qué medio le comunicaremos la respuesta a su solicitud?
                        </p>
                        <p className="paragraph-text">
                          Mediante correo electrónico.
                        </p>
                      </li>
                      <li>
                        <p className="paragraph-text">
                          ¿En qué medios se pueden reproducir los datos personales que,
                          en su caso, solicite?
                        </p>
                        <p className="paragraph-text">
                          Mediante correo electrónico.
                        </p>
                      </li>
                    </ul>
                    <p className="paragraph-text">
                      Los datos de contacto de la persona o departamento de datos
                      personales, que está a cargo de dar trámite a las solicitudes de
                      derechos ARCO, son los siguientes:
                    </p>
                    <p className="paragraph-text">
                      Departamento de Protección de Datos Personales
                    </p>
                    <p className="paragraph-text">
                      San Juan Tlihuaca 19. Col. San Juan Tlihuaca. Azcapotzalco CDMX. CP 02400
                      <br />
                      Correo electrónico:
                      {' '}
                      <a href="mailto:espiral.cero.psicoterapia@gmail.com">espiral.cero.psicoterapia@gmail.com</a>
                      <br />
                      Número telefónico: 5526370953
                    </p>
                  </p>
                </li>

                <li>
                  <p className="paragraph-text">
                    De la revocación del consentimiento para el uso de sus datos personales
                  </p>
                  <p className="paragraph-text">
                    Usted puede revocar el consentimiento que, en su caso, nos haya otorgado
                    para el tratamiento de sus datos personales. Es importante que tenga en
                    cuenta que no en todos los casos podremos atender su solicitud o concluir
                    el uso de forma inmediata dado que el uso de datos personales está
                    relacionado con el servicio brindado. Deberá considerar que para ciertos
                    fines, la revocación de su consentimiento implicará que no le podamos
                    seguir prestando el servicio que nos solicitó, o la conclusión de su
                    relación con nosotros.
                  </p>
                  <p className="paragraph-text">
                    Para revocar su consentimiento deberá presentar su solicitud a través
                    del siguiente medio:
                  </p>
                  <p className="paragraph-text">
                    <ul type="b">
                      <li>
                        Correo:
                        {' '}
                        <a href="mailto:espiral.cero.psicoterapia@gmail.com">espiral.cero.psicoterapia@gmail.com</a>
                      </li>
                      <li>Asunto: REVOCACIÓN DE DATOS PERSONALES</li>
                    </ul>
                  </p>
                  <p className="paragraph-text">
                    Con relación al procedimiento y requisitos para la revocación de su
                    consentimiento, le informamos lo siguiente:
                  </p>
                  <p className="paragraph-text">
                    <ul type="a">
                      <li>
                        <p className="paragraph-text">
                          ¿A través de qué medios pueden acreditar su identidad el
                          titular y, en su caso, su representante, así como la
                          personalidad este último?
                        </p>
                        <p className="paragraph-text">
                          Los documentos que acrediten su identidad o, en su caso,
                          la representación legal de la persona que realiza la solicitud a su nombre
                        </p>
                      </li>
                      <li>
                        <p className="paragraph-text">
                          ¿Qué información y/o documentación deberá contener la solicitud?
                        </p>
                        <p className="paragraph-text">
                          Su nombre, correo electrónico  y domicilio u otro medio para
                          comunicarte la respuesta a tu solicitud.
                        </p>
                      </li>
                      <li>
                        <p className="paragraph-text">
                          ¿En cuántos días le daremos respuesta a su solicitud?
                        </p>
                        <p className="paragraph-text">
                          15 días hábiles.
                        </p>
                      </li>
                      <li>
                        <p className="paragraph-text">
                          ¿Por qué medio le comunicaremos la respuesta a su solicitud?
                        </p>
                        <p className="paragraph-text">
                          Mediante correo electrónico.
                        </p>
                      </li>
                    </ul>
                  </p>
                </li>

                <li>
                  <p className="paragraph-text">
                    ¿Cómo puede limitar el uso o divulgación de su información personal?
                  </p>
                  <p className="paragraph-text">
                    Con objeto de que usted pueda limitar el uso y divulgación de su
                    información personal, le ofrecemos los siguientes medios:
                  </p>
                  <p className="paragraph-text">
                    <ul type="b">
                      <li>
                        Correo:
                        {' '}
                        <a href="mailto:espiral.cero.psicoterapia@gmail.com">espiral.cero.psicoterapia@gmail.com</a>
                      </li>
                      <li>Asunto: DIVULGACIÓN DE DATOS PERSONALES</li>
                    </ul>
                  </p>
                </li>

                <li>
                  <p className="paragraph-text">
                    El uso de tecnologías de rastreo en nuestro portal de internet
                  </p>
                  <p className="paragraph-text">
                    Le informamos que en nuestra página de internet utilizamos cookies,
                    web beacons u otras tecnologías, a través de las cuales es posible
                    monitorear su comportamiento como usuario de internet, así como
                    brindarle un mejor servicio y experiencia al navegar en nuestra
                    página. Los datos personales que recabamos a través de estas
                    tecnologías, los utilizaremos para los siguientes fines:
                  </p>
                  <p className="paragraph-text">
                    <ul type="a">
                      <li>
                        Sondeo de hábitos del usuario
                      </li>
                    </ul>
                  </p>
                  <p className="paragraph-text">
                    Los datos personales que obtenemos de estas tecnologías de rastreo
                    son los siguientes:
                  </p>
                  <p className="paragraph-text">
                    <ul type="a">
                      <li>Idioma preferido por el usuario</li>
                      <li>Tipo de navegador del usuario</li>
                      <li>Tipo de sistema operativo del usuario</li>
                    </ul>
                  </p>

                </li>

                <li>
                  <p className="paragraph-text">¿Cómo puede conocer los cambios en este aviso de privacidad?</p>
                  <p className="paragraph-text">
                    El presente aviso de privacidad puede sufrir modificaciones,
                    cambios o actualizaciones derivadas de nuevos requerimientos legales;
                    de nuestras propias necesidades por los productos o servicios que
                    ofrecemos; de nuestras prácticas de privacidad;
                    de cambios en nuestro modelo de negocio, o por otras causas.
                  </p>
                  <p className="paragraph-text">
                    Nos comprometemos a mantenerlo informado sobre los cambios
                    que pueda sufrir el presente aviso de privacidad, a través de: mediante la actualización
                    de la página de aviso de privacidad y/o por correo electrónico.
                  </p>

                </li>
              </ol>
            </p>

            <h1 className="main-title text-center">Términos y condiciones</h1>

            <p className="paragraph-text">
              Este contrato describe los términos y condiciones generales (en adelante únicamente
              “TÉRMINOS Y CONDICIONES”) aplicables al uso de los contenidos, productos y servicios
              ofrecidos a través del sitio https://www.espiralpuntocero.com (en adelante “SITIO WEB”),
              del cual es titular Eunice Hinojosa Olvera (en adelante “TITULAR”).
              Cualquier persona que desee acceder o hacer uso del sitio o servicios que en él se
              ofrecen, podrá hacerlo sujetándose a los presentes TÉRMINOS Y CONDICIONES, así
              como a políticas, premisas y principios incorporados al presente documento.
              En todo caso, cualquier persona que no acepte los presentes TÉRMINOS Y CONDICIONES,
              deberá abstenerse de utilizar el sitio web y/o adquirir los productos y servicios
              que es este caso son ofrecidos.
            </p>

            <p className="paragraph-text">
              <ol type="I">
                <li>
                  Del objeto
                  <p className="paragraph-text">
                    El objeto de los presentes términos y condiciones es regular el acceso
                    y la utilización del sitio web entendiendo por éste cualquier tipo de
                    contenido producto o servicio que se encuentra a disposición del público
                    en general dentro del dominio https://www.espiralpuntocero.com
                  </p>

                  <p className="paragraph-text">
                    El titular se reserva la facultad de modificar en cualquier momento
                    y sin previo aviso la presentación, los contenidos, la funcionalidad,
                    los productos, los servicios, y la configuración que pudiera estar
                    contenida en el SITIO WEB; en este sentido el usuario reconoce y acepta
                    que Eunice Hinojosa Olvera en cualquier momento podrá interrumpir,
                    desactivar o cancelar cualquiera de los elementos que conforman el
                    sitio web o el acceso a los mismos.
                  </p>

                  <p className="paragraph-text">
                    Además del costo de la conexión a internet en virtud de los servicios
                    que el usuario tenga contratados con algún proveedor de
                    telecomunicaciones, parte de los contenidos o servicios ofrecidos en el
                    sitio http://www.espiral.com o, en su caso, por terceros a través del
                    sitio web pueden estar sujetos a la contratación previa del contenido,
                    producto o servicio, en cuyo caso se especificará de forma clara y se
                    pondrá a disposición del usuario las condiciones generales o
                    particulares por las que se rige el acceso a dichos contenidos.
                  </p>

                  <p className="paragraph-text">
                    El acceso a parte de los contenidos y servicios del sitio web
                    podrá realizarse previa suscripción o registro previo del usuario.
                  </p>

                  <p className="paragraph-text">
                    El sitio web se encuentra dirigido exclusivamente a personas
                    que cuentan con la mayoría de edad (mayores de 18 años); en este
                    sentido, Eunice Hinojosa Olvera declina cualquier responsabilidad
                    por el incumplimiento de este requisito.
                  </p>

                  <p className="paragraph-text">
                    El sitio web está dirigido principalmente a usuarios residentes
                    en la República Mexicana, por lo cual Eunice Hinojosa Olvera
                    no asegura que el sitio web cumpla total o parcialmente con la
                    legislación de otros países, de forma que, si el usuario recibe o
                    tiene su domicilio establecido en otro país y decir acceder o utilizar
                    el sitio web lo hará bajo su propia responsabilidad y deberá
                    asegurarse de que tal acceso y navegación cumple con la legislación
                    local que le es aplicable, no asumiendo Eunice Hinojosa Olvera ninguna
                    responsabilidad que se pueda derivar de dichos actos.
                  </p>

                  <p className="paragraph-text">
                    Se hace del conocimiento del usuario que el titular podrá administrar
                    o gestionar el sitio web de manera directa a través de un tercero,
                    lo cual no modifica en ningún sentido lo establecido en los presentes
                    términos y condiciones.
                  </p>

                </li>

                <li>
                  Del Usuario

                  <p className="paragraph-text">
                    El acceso o utilización del sitio web, así como de los recursos
                    habilitados para interactuar con los usuarios, o entre le usuario
                    y el titular tales como medios para realizar publicaciones o comentarios,
                    confiere la condición de usuario del sitio web , por lo que quedará
                    sujeto a los presentes términos y condiciones, así como a sus ulteriores
                    modificaciones, sin perjuicio de la aplicación de la legislación aplicable,
                    por tanto se tendrán por aceptados desde el momento que se accede al sitio web.
                    Dada la relevancia de lo anterior, se recomienda al usuario revisar
                    las actualizaciones que se realicen en los presentes términos y condiciones.
                  </p>

                  <p className="paragraph-text">
                    Es responsabilidad del usuario usar el sitio web de acuerdo a la forma
                    en la que fue diseñado; en este sentido, queda prohibida la utilización
                    de cualquier tipo de software que automatice la interacción o descarga
                    de los contenidos o servicios proporcionados a través del sitio web.
                    Además, el usuario se compromete a utilizar la información, contenidos
                    o servicios ofrecidos a través del sitio web de manera lícita, sin
                    contravenir lo dispuesto en los presentes términos y condiciones,
                    la moral y el orden público, y se abstendrá de realizar cualquier acto
                    que pueda suponer una afectación de los derechos de terceros o
                    perjudique de algún modo el sitio web.
                  </p>

                  <p className="paragraph-text">
                    Asimismo, el usuario se compromete a proporcionar información lícita
                    y veraz en los formularios habilitados en el sitio web, en los cuales
                    el usuario tenga que proporcionar ciertos datos o información para el
                    acceso a algún contenido, producto o servicio ofrecido por el propio
                    sitio web. En todo caso, el usuario notificará de forma inmediata al
                    titular acerca de cualquier hecho que permita suponer el uso indebido de
                    la información registrada en dichos formularios, tales como robo, extravío,
                    o acceso no autorizado a cuentas y/o contraseñas, con el fin de proceder
                    a su inmediata cancelación.

                  </p>

                  <p className="paragraph-text">
                    Eunice Hinojosa Olvera se reserva el derecho de retirar todos aquellos
                    comentarios y aportaciones que vulneren la ley, el respeto, la dignidad
                    de la persona, que sean discriminatorios, atenten contra los derechos
                    de un tercero o el orden público, o bien, que  su juicio no resulten
                    adecuados para su publicación.

                  </p>

                  <p className="paragraph-text">
                    En cualquier caso, Eunice Hinojosa Olvera no será responsable de las
                    opiniones vertidas por los usuarios a través de comentarios o
                    publicaciones que estos realicen.

                  </p>

                  <p className="paragraph-text">
                    El solo acceso al sitio web no supone el establecimiento de ningún
                    tipo de relación entre el titular y el usuario.

                  </p>

                  <p className="paragraph-text">
                    Al tratarse de un sitio web dirigido exclusivamente a personas
                    mayores de edad, el usuario manifiesta ser mayor de edad y
                    disponer de la capacidad jurídica necesaria para sujetarse a l
                    os presentes términos y condiciones.

                  </p>

                </li>

                <li>
                  Del acceso y la navegación del sitio web

                  <p className="paragraph-text">
                    El titular no garantiza de ningún modo la continuidad y disponibilidad
                    de los contenidos, productos o servicios ofrecidos a través del
                    sitio web, no obstante, el titular llevará a cabo las acciones que
                    de acuerdo a sus posibilidades le permitan mantener el buen
                    funcionamiento del sitio web, sin que esto suponga alguna responsabilidad
                    de parte de Eunice Hinojosa Olvera.

                  </p>

                  <p className="paragraph-text">
                    De igual manera Eunice Hinojosa Olvera no será responsable ni garantiza
                    que el contenido o software al que pueda acceder a través del sitio web,
                    se encuentre libre de errores, software malicioso, o que pueda causar
                    algún daño a nivel de software o hardware en el equipo a través del cual
                    el usuario accede al sitio WEB.

                  </p>

                  <p className="paragraph-text">
                    El titular tampoco se hace responsable de los daños que pudiesen
                    ocasionarse por un uso inadecuado del sitio web. En ningún caso
                    Eunice Hinojosa Olvera será responsable por las pérdidas, daños o
                    perjuicios de cualquier tipo que surjan por el sólo acceso o
                    utilización del sitio web.

                  </p>

                </li>

                <li>
                  Política de privacidad y protección de datos
                  <p className="paragraph-text">
                    De conformidad con lo establecido en la Ley Federal de Protección de datos,
                    el titular se compromete a adoptar las medidas necesarias que estén a su
                    alcance para asegurar la privacidad de los datos personales recabados de
                    forma que se garantice su seguridad, se evite su alteración, pérdida o
                    tratamiento no autorizado.

                  </p>

                  <p className="paragraph-text">
                    Además, a efecto de dar cumplimiento a lo establecido en la Ley Federal
                    de Protección de Datos Personales en Posesión de Particulares, todo dato
                    personal que sea recabado a través del sitio web, será tratado de conformidad
                    con los principios de licitud, calidad, finalidad, lealtad y responsabilidad.
                    Todo tratamiento de conformidad con los principios de licitud, calidad,
                    finalidad, lealtad, y responsabilidad. Todo tratamiento de datos personales
                    quedará sujeto al consentimiento de su titular. En todo caso, la utilización
                    de datos financieros o patrimoniales, requerirán de autorización expresa de
                    sus titulares, no obstante, esta podrá darse a través del sitio web
                    utilizando los mecanismos habilitados para tal efecto y en todo caso se dará
                    la mayor diligencia y cuidado a este tipo de datos. Lo mismo ocurrirá en el
                    caso de datos personales sensibles, considerando por estos aquellos que debido
                    a una utilización indebida puedan dar origen a discriminación o su utilización
                    conlleve a un riesgo para el titular.

                  </p>

                  <p className="paragraph-text">
                    En todo momento se procurará que los datos personales contenidos en las bases
                    de datos o archivos que en su caso se utilicen, sean pertinentes, correctos,
                    y actualizados para los fines para los cuales fueron recabados.

                  </p>

                  <p className="paragraph-text">
                    El tratamiento de datos personales se limitará al cumplimiento de las
                    finalidades previas en el Aviso de Privacidad el cual se encuentra disponible
                    en esta misma página.

                  </p>

                  <p className="paragraph-text">
                    El sitio web podrá incluir hipervínculos o enlaces que permitan acceder a
                    páginas web de terceros distintos de Eunice Hinojosa Olvera. Los titulares
                    de dichos sitios web dispondrán de sus propias políticas de privacidad y
                    protección de datos, por lo cual Eunice Hinojosa Olvera no asume ningún
                    tipo de responsabilidad por los datos que sean facilitados por el usuario a
                    través de cualquier sitio web distinto a https://www.espiralpuntocero.com/

                  </p>

                  <p className="paragraph-text">
                    Eunice Hinojosa Olvera se reserva el derecho de modificar su política de
                    privacidad de acuerdo a sus necesidades o derivado de algún cambio en la
                    legislación.  El acceso al sitio web después de dichos cambios,
                    implica la aceptación de los mismos.

                  </p>

                  <p className="paragraph-text">
                    El acceso al sitio web conlleva la utilización de cookies (pequeñas
                    cantidades de información que se almacena en el navegador) La información
                    recabada por el uso de cookies será utilizada para mejorar el sitio web,
                    detectar errores y posibles necesidades que el usuario pueda tener, lo
                    anterior a efecto de ofrecer a los usuarios servicios y contenidos de mejor
                    calidad. La información recabada por el uso de cookies no permite la
                    identificación individual de los usuarios. En caso de que el usuario no
                    quiera proporcionar este tipo de información deberá rechazar, inhabilitar,
                    restringir o eliminar el uso de cookies. En el caso de que el usuario decida
                    rechazar el uso de cookies, podrá seguir haciendo uso del sitio web, sin
                    embargo algunas de sus funciones quedarán deshabilitadas.

                  </p>

                </li>

                <li>
                  De Los Enlaces Posibles
                  <p className="paragraph-text">
                    El sitio web puede contener enlaces, contenidos, servicios o funciones de
                    otros sitios de internet pertenecientes  o gestionados por terceros
                    (imágenes, videos, motores de búsqueda, etc.).

                  </p>

                  <p className="paragraph-text">
                    La utilización de los recursos antes mencionados, tiene por objeto la
                    mejora de la experiencia del usuario que haga uso del sitio web, sin que
                    pueda considerarse una invitación, sugerencia o recomendación para hacer
                    uso de esos sitios externos. Eunice Hinojosa Olvera revisará o controlará
                    el contenido de los sitios externos , de igual forma no hace propios
                    los contenidos, productos, servicios y cualquier otro material referido
                    en los sitios externos; por lo anterior no se garantizará de ninguna
                    manera la seguridad, exactitud, veracidad, validez o legalidad de los
                    sitios externos a los que se puede tener acceso a través del sitio web.
                    Eunice Hinojosa Olvera no se hace responsable por los daños que puedan
                    causarse al consultar sitios externos a través de los cuales se pueda
                    acceder mediante el sitio web.

                  </p>

                </li>

                <li>
                  De La Propiedad Intelectual
                  <p className="paragraph-text">
                    Eunice Hinojosa Olvera por sí o por parte concesionaria, es titular de
                    todos los derechos de propiedad intelectual del sitio web, entendiendo
                    por éste el código fuente que da origen a su funcionamiento, así como
                    las imágenes (de no ser el caso se dará siempre el crédito a la imagen
                    fuente original en el sitio web), archivos de audio, videos, logotipos,
                    marcas, combinaciones de colores, fuentes, estructuras, diseños y demás
                    que lo distinguen. Será por lo tanto, protegidas por la ley mexicana
                    aplicable  en materia de propiedad intelectual. Por lo anterior queda
                    totalmente prohibida la reproducción, distribución, difusión de los
                    contenidos del sitio web, con fines comerciales, en cualquier soporte,
                    o por cualquier medio, sin la autorización de Eunice Hinojosa Olvera.

                  </p>

                  <p className="paragraph-text">
                    El usuario se compromete a respetar los derechos intelectuales. No
                    obstante podrá visualizar los elementos del sitio web, imprimirlos,
                    copiarlos, almacenarlos siempre y cuando sea para uso
                    estrictamente personal.

                  </p>

                  <p className="paragraph-text">
                    Por otro lado, el usuario, se abstendrá de suprimir, alterar, o
                    manipular cualquier elemento, archivo o contenido del sitio web;
                    por ningún motivo realizará actos tendientes a vulnerar la seguridad,
                    los archivos o bases de datos que se encuentren protegidos.

                  </p>

                  <p className="paragraph-text">
                    En caso de que el usuario considere que cualquiera de los contenidos
                    del sitio web supongan un riesgo de los derechos de protección de
                    la propiedad intelectual, deberá comunicarlo inmediatamente a
                    Eunice Hinojosa Olvera a través de los medios de contacto
                    disponibles en el sitio web y/o a través del siguiente medio:

                  </p>

                  <p className="paragraph-text">
                    <ul type="b">
                      <li>
                        <a href="mailto:espiral.cero.psicoterapia@gmail.com">espiral.cero.psicoterapia@gmail.com</a>
                      </li>
                    </ul>
                  </p>

                </li>

                <li>
                  De La Legislación Aplicable
                  <p className="paragraph-text">
                    Eunice Hinojosa Olvera se reserva el derecho de presentar
                    las acciones civiles o penales que considere necesarias
                    por la utilización indebida del sitio web, sus contenidos,
                    productos, servicios, o por el incumplimiento de los presentes
                    términos y condiciones.

                  </p>

                  <p className="paragraph-text">
                    La relación entre usuario y titular se regirá por la legislación
                    vigente en la Ciudad de México.

                  </p>

                </li>

              </ol>
            </p>

          </div>
        </Col>
        <Col>
          <img
            className="privacy-main d-none d-xl-inline"
            alt="Privacidad principal"
            src={privacy}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Privacy;
