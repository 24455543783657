import React from 'react';

// Styles
import './Main.scss';

// Components
import { About } from '../about/About';
import { Home } from '../home/Home';
import { Services } from '../services/Services';
import { Resources } from '../resources/Resources';
import { Contact } from '../contact/Contact';
import { Footer } from '../footer/Footer';
import TermsAgreement from '../TermsAgreement/TermsAgreement';

export const Main = () => (
  <>
    <TermsAgreement />
    <Home />
    <About />
    <Services />
    <Resources />
    <Contact />
    <Footer />
  </>
);
