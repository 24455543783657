import React, { useState } from 'react';

// Styles
import './TermsAgreement.scss';

// Component
import TermsAgreementWrapper from './TermsAgreementWrapper';

const TermsAgreement = () => {

  const [acceptedTerms, setAcceptedTerms] = useState(localStorage.getItem('acceptedTerms'));

  const termsAccepted = () => {
    localStorage.setItem('acceptedTerms', true);
    setAcceptedTerms(true);
  };

  return (
    !acceptedTerms && <TermsAgreementWrapper onClick={() => termsAccepted()} />
  );

};

export default TermsAgreement;
