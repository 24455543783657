import React, { useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import GoogleMapReact from 'google-map-react';
import { Element } from 'react-scroll';

// Styles
import './Services.scss';

// Images
import mapPin from '../../assets/img/map-pin.svg';
import logo from '../../assets/img/logo.svg';
import thFaceBg from '../../assets/img/th-face-bg.svg';
import thOnlineBg from '../../assets/img/th-online-bg.svg';
import onlineMain from '../../assets/img/therapy-bg.svg';
import coursesMain from '../../assets/img/courses-bg.svg';
import serviceFaceToFace from '../../assets/img/services-1.svg';
import serviceOnline from '../../assets/img/services-2.svg';
import coursesButton from '../../assets/img/courses-button-1.svg';

// Classes
import { PopUp } from '../popup/Popup';

export const Services = () => {

  const history = useHistory();

  const mapRef = useRef(null);
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const coords = {
    center: {
      lat: 19.458617,
      lng: -99.177995
    },
    zoom: 15
  };

  const handleWorkshopClick = () => {
    history.push('/talleres');
  };

  const MapPin = () => (
    <div>
      <img
        src={mapPin}
        className="map-pin"
        alt="Pin de ubicación de consultorio"
      />
    </div>
  );

  return (
    <Element name="services">
      <section
        ref={mapRef}
        id="face-to-face-section"
        className="main-therapies-section"
      >
        <Row className="face-to-face-therapy-section">
          <Col xs={12}>
            <img
              src={thFaceBg}
              className="therapies-bg"
              alt="Bg terapia presencial"
            />
          </Col>
          <Col
            id="services"
            className="ml-md-4 ml-xl-5"
            md="6"
          >
            <h1 className="col ml-xl-5 mb-lg-3 main-title face-to-face-therapy-header">
              Terapia presencial
            </h1>
            <br />
            <div className="paragraph-group">
              <p className="col ml-xl-5 paragraph-text services-text">
                Atravesar por situaciones complejas es parte de la vida;
                <br className="d-none d-xl-block" />
                <span> </span>
                todos hemos pasado por circunstancias que nos detonan
                <br className="d-none d-xl-block" />
                <span> </span>
                miedo, tristeza, dolor, dudas.
              </p>
            </div>
            <p className="col ml-xl-5 mb-3 paragraph-text services-text">
              A través de la terapia psicológica podrás:
            </p>
            <ul className="col ml-xl-5">
              <li className="ml-3 mb-3 paragraph-text">
                <span>
                  Identificar las emociones y sentimientos que acompañan
                </span>
                <br className="d-none d-xl-block" />
                <span> </span>
                <span>tus situaciones de vida.</span>
              </li>
              <li className="ml-3 mb-3 paragraph-text">
                <span>Desarrollar mecanismos de expresividad emocional</span>
                <br className="d-none d-xl-block" />
                <span> </span>
                <span>asertivos y congruentes.</span>
              </li>
              <li className="ml-3 mb-3 paragraph-text">
                <span>
                  Cuestionar todo aquello que has aprendido como cierto
                </span>
                <br className="d-none d-xl-block" />
                <span> </span>
                <span>
                  y que atraviesa tus pensamientos, acciones y decisiones.
                </span>
              </li>
              <li className="ml-3 paragraph-text">
                <span>Reaprender nuevas formas de SER y HACER.</span>
              </li>
            </ul>
            <div role="button">
              <img
                role="presentation"
                alt="Botón presencial"
                src={serviceFaceToFace}
                onClick={handleShow}
                className="face-to-face-button"
              />
            </div>
          </Col>
          <Col md="5">
            <div
              id="room-map"
              className="therapy-map"
            >
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: 'AIzaSyAf4MVYMxQb-SvFYfdRv4XkmSXQD1zG8Hw'
                }}
                defaultCenter={coords.center}
                defaultZoom={coords.zoom}
              >
                <MapPin lat={19.458617} lng={-99.177995} />
              </GoogleMapReact>
            </div>
            <Col>
              <address className="paragraph-text text-white address-text">
                Mar Marmara 352 A.
                <br />
                Colonia Popotla. Del. Miguel Hidalgo
              </address>
            </Col>
          </Col>
        </Row>
      </section>

      <section id="online-section">
        <Row className="h-100">
          <Col xs={12}>
            <img
              src={thOnlineBg}
              className="therapies-bg th-online-bg"
              alt="Bg terapia presencial"
            />
          </Col>
          <Col
            md="7"
            className="h-100 d-none d-md-inline"
          >
            <img
              alt="online main"
              className="online-main"
              src={onlineMain}
            />
            <cite className="online-quote">
              <a
                href="http://dx.doi.org/10.1037/a0032406"
                target="blank"
              >
                <p className="ml-4">
                  <strong>
                    *Eells, T., Barrett, S., Wright, J. H. y Thase, M. (2014).
                    Computer-assisted cognitive-behavior therapy for depression.
                  </strong>
                  <br className="d-none d-xl-block" />
                  <span> </span>
                  <strong>Psychotherapy (Chicago, Ill.), 51, 191.</strong>
                </p>
              </a>
            </cite>
          </Col>
          <Col
            xs="12"
            md="5"
          >
            <h1 className="main-title mb-4 text-center online-therapy-header pt-xl-5 pt-lg-4">
              Terapia online
            </h1>
            <br />
            <p className="paragraph-text services-text online-text">
              Las herramientas tecnológicas actuales nos dan la oportunidad
              <br className="d-none d-xl-block" />
              <span> </span>
              de diversificar las opciones del trabajo psicoterapéutico.
              <br className="d-none d-xl-block" />
              <span> </span>
              &quot;Los resultados de la terapia online para fenómenos como la
              depresión y ansiedad,
              <br className="d-none d-xl-block" />
              <span> </span>
              han mostrado resultados esperanzadores&quot; ¡confirmando su
              efectividad!
            </p>
            <p className="paragraph-text services-text online-text">
              ¿Cuándo es buena opción la terapia online?
            </p>
            <ul className="online-list">
              <li className="paragraph-text online-text">
                <span>
                  Existe algún impedimento físico o psicológico que dificulta o
                </span>
                <br />
                <span>imposibilita el traslado al espacio terapéutico.</span>
              </li>
              <li className="paragraph-text online-text">
                <span>Cuentas con tiempos complejos que complican</span>
                <br />
                <span>acudir de manera presencial.</span>
              </li>
              <li className="paragraph-text online-text">
                <span>Te sientes cómodo y familiarizado</span>
                <br />
                <span>con las herramientas online.</span>
              </li>
            </ul>
            <br />
            <div role="button">
              <img
                role="presentation"
                alt="Botón online"
                src={serviceOnline}
                onClick={handleShow}
                className="online-button"
              />
            </div>
            <Col
              xs="12"
              className="d-sm-inline d-md-none"
            >
              <img
                alt="online main"
                className="online-main"
                src={onlineMain}
              />
              <cite className="online-quote">
                <a
                  href="http://dx.doi.org/10.1037/a0032406"
                  target="blank"
                >
                  <p className="ml-lg-4">
                    <strong>
                      *Eells, T., Barrett, S., Wright, J. H. y Thase, M. (2014).
                      Computer-assisted cognitive-behavior therapy for depression.
                      Psychotherapy (Chicago, Ill.), 51, 191.
                    </strong>
                  </p>
                </a>
              </cite>
            </Col>
          </Col>

        </Row>
      </section>

      <section
        id="courses-section"
        className="courses-section ml-md-4 ml-lg-5"
      >
        <Row>

          <Col
            xs="12"
            md="4"
          >
            <h1
              id="courses-header"
              className="main-title mb-4 text-center"
            >
              Cursos y Talleres
            </h1>
          </Col>

          <Col className="d-none d-md-inline">
            <img
              className="courses-main"
              src={coursesMain}
              alt="courses"
            />
          </Col>

          <Col xs="12">
            <p className="col paragraph-text services-text mb-0">
              En
              <img
                src={logo}
                className="text-logo"
                alt="Espiral Punto Cero"
              />
              queremos compartir información que nos permita aprender, reaprender
              <br className="d-none d-xl-block" />
              <span> </span>
              y vernos desde otro punto de vista para generar el cambio que necesitamos.
              <br />
            </p>
          </Col>

          <Col
            xs="12"
            xl="7"
          >
            <div className="text-center row align-items-center">
              <Col>
                <br />
                <span className="offer-text text-primary">
                  <i>¡Revisa nuestra oferta de cursos y talleres!</i>
                </span>
                <br />
              </Col>
            </div>
          </Col>

          <Col
            className="mt-2 mt-md-5"
            xs="12"
            md="5"
            lg="5"
          >
            <p className="paragraph-text services-text text-center">
              Taller:
              <br />
              Del amor propio... y otras
              <br />
              cosas que no siempre
              <br className="d-none d-xl-block" />
              <span> </span>
              andan por ahí.
            </p>
            <div
              role="button"
              className="text-center"
            >
              <img
                role="presentation"
                alt="Botón cursos"
                src={coursesButton}
                onClick={() => handleWorkshopClick()}
                className="courses-button-mitos"
              />
            </div>
          </Col>

          <Col
            xs="12"
            md="6"
            lg="6"
            xl="4"
            className="ml-xl-n5 courses-last-section"
          >
            <p className="paragraph-text services-text text-center">
              Taller:
              <br />
              Repensando el amor y
              <br />
              sus mitos románticos.
            </p>
            <div
              role="button"
              className="text-center"
            >
              <img
                role="presentation"
                alt="Botón cursos"
                src={coursesButton}
                onClick={() => handleWorkshopClick()}
                className="courses-button-amor"
              />
            </div>
          </Col>

          <Col className="d-sm-block d-md-none">
            <img
              className="courses-main"
              src={coursesMain}
              alt="courses"
            />
          </Col>

        </Row>

        <PopUp
          show={show}
          handleClose={handleClose}
        />
      </section>

    </Element>
  );
};
