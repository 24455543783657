import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';

// Components
import { Footer } from '../footer/Footer';

// Styles
import './Workshop.scss';

// Images
import logo from '../../assets/img/logo.svg';
import workshopMain from '../../assets/img/workshop-main.svg';
import workshopFloor from '../../assets/img/workshop-floor.svg';
import mirrorGirl from '../../assets/img/mirror-girl.svg';
import downloadButton from '../../assets/img/download-button.svg';
import heartCouple from '../../assets/img/heart-couple.svg';

const Workshop = () => {

  useEffect(
    () => {
      setTimeout(() => window.scrollTo(0, 0));
    },
    []
  );

  return (
    <div id="top">
      <section id="workshop-section">
        <img
          className="logo-main"
          alt="Logo principal"
          src={logo}
        />
        <h1 className="text-primary main-title header-workshop">
          Talleres
        </h1>
        <Col
          xs="12"
          className="text-center"
        >
          <img
            className="workshop-main"
            alt="Background principal"
            src={workshopMain}
          />
        </Col>
        <img
          className="floor-main"
          alt="Floor principal"
          src={workshopFloor}
        />
        <Row className="first-ws-row">
          <Col
            xs="12"
            lg="6"
            className="text-center"
          >
            <img
              className="mirror-girl"
              alt="Chica del espejo"
              src={mirrorGirl}
            />
          </Col>
          <Col
            xs="12"
            lg="6"
            className="text-center"
          >
            <h1 className="main-title">Autoestima</h1>
            <p className="paragraph-text">
              Del amor propio... y otras
              <br className="d-none d-xl-block" />
              <span> </span>
              cosas que no siempre
              <br className="d-none d-xl-block" />
              <span> </span>
              andan por ahí
            </p>
            <div
              role="button"
              className="text-center"
            >
              <a
                href="https://mailchi.mp/c52c5994db11/v1thz6wsr4"
                target="blank"
              >
                <img
                  role="presentation"
                  alt="Botón descarga"
                  src={downloadButton}
                  className="download-button"
                />
              </a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col
            xs="12"
            lg="6"
            className="text-center"
          >
            <h1 className="main-title">
              Mitos del Amor
              <br className="d-none d-xl-block" />
              <span> </span>
              Romantico

            </h1>
            <p className="paragraph-text">
              Reconstruyendo nuestras
              <br className="d-none d-xl-block" />
              <span> </span>
              creencias sobre el amor
            </p>
            <div
              role="button"
              className="text-center"
            >
              <a
                href="https://mailchi.mp/b306e1ac9e2e/ihxajd5ghc"
                target="blank"
              >
                <img
                  role="presentation"
                  alt="Botón descarga"
                  src={downloadButton}
                  className="download-button"
                />
              </a>
            </div>
          </Col>
          <Col
            xs="12"
            lg="6"
            className="text-center"
          >
            <img
              className="heart-couple"
              alt="Chica del espejo"
              src={heartCouple}
            />
          </Col>
        </Row>
        <Footer />
      </section>
    </div>
  );
};

export default Workshop;
