import React from 'react';
import { Link } from 'react-router-dom';

const TermsAgreementText = () => (
  <div className="p-2 my-auto w-100">
    <p className="m-0">
      Usamos cookies para mejorar tu experiencia. Al aceptar,
      manifiestas haber leído, entendido y dar tu consentimiento
      a nuestro
      {' '}
      <Link to="privacy" className="terms">aviso de privacidad, términos y condiciones y cookies</Link>
      .
    </p>

  </div>
);

export default TermsAgreementText;
