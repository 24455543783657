import React, { useEffect, useState } from 'react';
import * as smoothscroll from 'smoothscroll-polyfill';
import { Alert, Button } from 'react-bootstrap';
import * as serviceWorker from './serviceWorker';

import { Layout } from './layout/Layout';
import './App.scss';

const App = () => {

  const [newVersion, setNewVersion] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState({});

  const loadScrollBehaviour = () => {
    if (!('scrollBehavior' in document.documentElement.style)) {
      smoothscroll.polyfill();
    }
  };

  const onServiceWorkerUpdate = (registration) => {
    setWaitingWorker(registration && registration.waiting);
    setNewVersion(true);
  };

  const updateServiceWorker = () => {
    waitingWorker.postMessage({ type: 'SKIP_WAITING' });
    setNewVersion(false);
    window.location.reload();
  };

  const refreshAction = () => (
    <div
      id="update-alert"
      className="fixed-bottom px-md-3"
    >
      <Alert
        variant="info"
        className="m-0 fixed-bottom d-flex"
      >
        <div className="p-2 my-auto w-100">
          <p className="m-0">
            ¡Hay una nueva versión disponible!
          </p>
        </div>
        <div className="p-2 flex-shrink-1">
          <Button
            onClick={() => updateServiceWorker()}
            variant="outline-info"
          >
            Actualizar
          </Button>
        </div>
      </Alert>
    </div>
  );

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      serviceWorker.register({ onUpdate: onServiceWorkerUpdate });
    }
    loadScrollBehaviour();
  }, []);

  return (
    <div className="App">
      { newVersion ? refreshAction() : null }
      <Layout />
    </div>
  );
};

export default App;
