import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';

// Styles
import './FAQ.scss';

// Images
import logo from '../../assets/img/logo.svg';
import faqImg from '../../assets/img/faq-bg.svg';

// Components
import { Footer } from '../footer/Footer';

export const FAQ = () => {

  useEffect(
    () => {
      setTimeout(() => window.scrollTo(0, 0));
    },
    []
  );

  return (
    <>
      <section id="faq">
        <Row>
          <Col
            xs="12"
            md="6"
            className="row"
          >
            <Col xl="12">
              <img
                id="faq-logo"
                src={logo}
                className="ml-3 d-none d-md-inline"
                alt="Espiral punto Cero"
              />
            </Col>
            <Col
              xl="12"
              className="d-none d-md-inline"
            >
              <img
                id="faq-image"
                src={faqImg}
                alt="Espiral punto Cero"
              />
            </Col>
          </Col>
          <Col
            className="faq-paragraphs"
            xs="12"
            md="6"
          >
            <div className="faq-first-level">
              <h2 className="col faq-header">
                ¿Cómo funciona la terapia psicológica en
                <img
                  className="text-logo"
                  src={logo}
                  alt="Espiral punto Cero"
                />
                ?
              </h2>
              <ul id="faq-list">
                <li className="col paragraph-text faq-text">Agendamos una sesión.</li>
                <li className="col paragraph-text faq-text">
                  En la primera cita, después de escuchar tu motivo de consulta, devolvemos las primeras
                  impresiones psicológicas y un posible camino y tiempo de tratamiento.
                </li>
                <li className="col paragraph-text faq-text">
                  Realizarás actividades y ejercicios psicoterapéuticos que te ayudarán a trabajar
                  fuera de tu espacio de sesión.
                </li>
                <li className="col paragraph-text faq-text">Haremos cortes al proceso en función de los avances.</li>
                <li className="col paragraph-text faq-text">¡Terminamos el proceso!</li>
              </ul>
              <h2 className="col faq-header">
                ¿Cómo sé cuál de los enfoques de psicoterapia es el mejor para mí?
              </h2>
              <br />
              <p className="col paragraph-text faq-text">
                Recuerda que existen varios enfoques de trabajo psicológico; desde el psicoanálisis hasta
                la escuela integrativa;
              </p>
              <p className="col paragraph-text faq-text">
                cada enfoque tiene premisas y procesos de trabajo claros. La mejor alternativa es que
                preguntes a tu terapeuta
              </p>
              <p className="col paragraph-text faq-text">sobre su enfoque de trabajo y las premisas del mismo.</p>
              <br />
              <p className="col paragraph-text faq-text">
                ¡Estar informado es un derecho y lo más importante es que te sientas en un espacio seguro,
                respetuoso,
              </p>
              <p className="col paragraph-text faq-text">empático y confiable!</p>
            </div>
            <br />
            <h2 className="col faq-header">
              ¿Cuánto tiempo debe durar un proceso psicoterapéutico?
            </h2>
            <br />
            <p className="col paragraph-text faq-text">Esto depende de varios factores: el tipo de terapia, tu motivo de consulta,</p>
            <p className="col paragraph-text faq-text">la estrategia psicoterapéutica que se establezca y los avances durante el</p>
            <p className="col paragraph-text faq-text">proceso son sólo algunos de los elementos a considerar.</p>
            <br />
            <p className="col paragraph-text faq-text">¡Recuerda siempre hacer las preguntas necesarias al especialista!</p>
          </Col>
          <Col
            xl="12"
            className="faq-image-wrapper d-sm-inline d-md-none"
          >
            <img
              id="faq-image"
              src={faqImg}
              alt="Espiral punto Cero"
            />
          </Col>
        </Row>
      </section>
      <Footer />
    </>
  );

};
