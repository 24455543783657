import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

const ConfirmationPopupContent = ({ onHide }) => (
  <section id="confirmation-content">
    <Row className="text-center">
      <Col xs={12}>
        <p className="paragraph-text text-center text-white">
          Gracias por ponerte en contacto conmigo, a la brevedad me comunicaré contigo
        </p>
      </Col>
      <Col className="text-center p-2">
        <button
          type="button"
          onClick={onHide}
          className="btn btn-secondary btn-confirmation"
        >
          De acuerdo
        </button>
      </Col>
    </Row>
  </section>
);

ConfirmationPopupContent.propTypes = {
  onHide: PropTypes.func.isRequired
};

export default ConfirmationPopupContent;
