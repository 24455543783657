import React from 'react';
import { Carousel, Col, Row } from 'react-bootstrap';
import { Element } from 'react-scroll';

// Styles
import './Resources.scss';

// Images
import mainRecursos from '../../assets/img/resources-bg.svg';
import commentsMain from '../../assets/img/comments-bg.svg';
import downloadButton from '../../assets/img/download-button.svg';
import ebook1 from '../../assets/img/ebook-1.png';
import ebook2 from '../../assets/img/ebook-2.png';

export const Resources = () => (
  <Element name="resources">
    <section
      id="ebooks-section"
    >
      <Row>
        <Col
          id="resources"
          className="ml-lg-6"
          md="7"
          xl="5"
        >
          <h1
            className="ebooks-header main-title mb-4 mt-4 text-center"
          >
            E-books gratuitos
          </h1>
          <Carousel
            indicators={false}
            className="mx-6"
          >
            <Carousel.Item>
              <img
                id="ebook-1"
                className="d-block w-100"
                src={ebook1}
                alt="Ebook 1"
              />
              <Carousel.Caption>
                <div
                  role="button"
                  className="text-center"
                >
                  <a
                    href="https://mailchi.mp/0e784389ca58/uf967dq8e5"
                    target="blank"
                  >
                    <img
                      id="ebook-download-1"
                      role="presentation"
                      alt="Botón cursos"
                      src={downloadButton}
                      className="download-button"
                    />
                  </a>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                id="ebook-2"
                className="d-block w-100"
                src={ebook2}
                alt="Ebook 2"
              />

              <Carousel.Caption>
                <div
                  role="button"
                  className="text-center"
                >
                  <a
                    href="https://mailchi.mp/a11276bba17f/1imr713e38"
                    target="blank"
                  >
                    <img
                      id="ebook-download-2"
                      role="presentation"
                      alt="Botón cursos"
                      src={downloadButton}
                      className="download-button"
                    />
                  </a>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </Col>
        <Col
          sm="12"
          md="5"
          xl="6"
        >
          <img
            src={mainRecursos}
            className="ebooks-image"
            alt="Recursos"
          />
        </Col>
      </Row>
    </section>

    <section
      id="comments-section"
    >
      <Row className="mt-5">

        <Col
          xs="12"
          className="d-none d-md-inline"
        >
          <img
            src={commentsMain}
            className="comments-image"
            alt="Comentarios"
          />
        </Col>

        <Col
          xs="12"
          md="6"
        >
          <h1 className="comments-header text-center mt-4 mr-lg-5 main-title"><i>Comentarios de usuarios</i></h1>
        </Col>
        <Col className="comments-paragraph-wrapper">
          <br />
          <Carousel
            controls={false}
            fade={true}
            indicators={false}
          >
            <Carousel.Item>
              <p className="col paragraph-text comments-text">
                <i>
                  Fue increíble trabajar con Eunice, una experiencia que me
                  <br className="d-none d-xl-block" />
                  <span> </span>
                  cambió la vida porque aprendí a verla desde otra
                  <br className="d-none d-xl-block" />
                  <span> </span>
                  perspectiva, volví a amar mi cuerpo y mi persona.
                  <br className="d-none d-xl-block" />
                  <span> </span>
                  Definitivamente pude identificar muchas de mis fortalezas.
                </i>
              </p>
              <p className="col paragraph-text comments-text">
                <i>
                  El autoconocimiento emocional que adquirí con Eunice me
                  <br className="d-none d-xl-block" />
                  <span> </span>
                  está permitiendo llevar a cabo una vida libre y sana. Ahora
                  <br className="d-none d-xl-block" />
                  <span> </span>
                  sigo desarrollándome integralmente en mis diferentes
                  <br className="d-none d-xl-block" />
                  <span> </span>
                  ámbitos: vida profesional, personal y familiar, entre otras.
                </i>
              </p>
              <br />
              <p className="col paragraph-text comments-text">
                <i>
                  Así que la verdad, me quedo muy contento me llevo una
                  <br className="d-none d-xl-block" />
                  <span> </span>
                  excelente experiencia, un muy buen sabor de boca y me
                  <br className="d-none d-xl-block" />
                  <span> </span>
                  siento feliz de haber conocido a una mujer tan profesional y
                  <br className="d-none d-xl-block" />
                  <span> </span>
                  con tanta sabiduria como lo es Eunice.
                </i>
              </p>
              <br />
              <p className="col paragraph-text comments-text"><i>Muchas gracias</i></p>
              <br />
              <p className="col paragraph-text comments-text"><i><b>Martín M.</b></i></p>
              <p className="col paragraph-text comments-text"><i><b>23 años</b></i></p>
            </Carousel.Item>
            <Carousel.Item>
              <p className="col paragraph-text comments-text">
                <i>
                  Sinceramente, mi experiencia tomando terapia con la
                  <br className="d-none d-xl-block" />
                  <span> </span>
                  Dra. Eunice fue de lo mejor que me pudo haber
                  <br className="d-none d-xl-block" />
                  <span> </span>
                  pasado a mi corta edad.
                  <br className="d-none d-xl-block" />
                  <span> </span>
                  La verdad es que gracias a ella
                  <br className="d-none d-xl-block" />
                  <span> </span>
                  comprendí muchísimas cosas y mi actitud mejoró bastante
                </i>
              </p>
              <br />
              <p className="col paragraph-text comments-text">
                <i>
                  La Dra. es una gran profesionista que sabe escucharte
                  <br className="d-none d-xl-block" />
                  <span> </span>
                  y guiarte para ser una mejor persona y enfrentarte a la vida.
                </i>
              </p>
              <br />
              <p className="col paragraph-text comments-text"><i><b>Jessica.</b></i></p>
              <p className="col paragraph-text comments-text"><i><b>17 años</b></i></p>
            </Carousel.Item>
            <Carousel.Item>
              <p className="col paragraph-text comments-text">
                <i>
                  A veces damos por sentado que creceremos siendo
                  <br className="d-none d-xl-block" />
                  <span> </span>
                  adultos funcionales. Pero crecemos con muchos asuntos que
                  <br className="d-none d-xl-block" />
                  <span> </span>
                  si no abordamos, pueden representar obstáculos
                  <br className="d-none d-xl-block" />
                  <span> </span>
                  en nuestro crecimiento personal.
                </i>
              </p>
              <br />
              <p className="col paragraph-text comments-text">
                <i>
                  Eunice me ayudó a estar en paz con muchas circunstancias
                  <br className="d-none d-xl-block" />
                  <span> </span>
                  que hoy me permiten ser feliz.
                </i>
              </p>
              <br />
              <p className="col paragraph-text comments-text">
                <i>
                  Es LA persona a quien acudir para abordar y enfrentar
                  <br className="d-none d-xl-block" />
                  <span> </span>
                  todo aquello que nos impide ser verdaderamente felices.
                  <br className="d-none d-xl-block" />
                  <span> </span>
                  No tengo más que agradecimiento.
                </i>
              </p>
              <br />
              <p className="col paragraph-text comments-text"><i><b>María.</b></i></p>
              <p className="col paragraph-text comments-text"><i><b>34 años</b></i></p>
            </Carousel.Item>
          </Carousel>
        </Col>

        <Col
          xs="12"
          className="d-sm-inline d-md-none"
        >
          <img
            src={commentsMain}
            className="comments-image"
            alt="Comentarios"
          />
        </Col>
      </Row>
    </section>
  </Element>
);
