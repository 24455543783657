import React from 'react';
import Container from 'react-bootstrap/Container';

// Components
import { Menu } from './Menu';

export const Layout = () => (
  <Container className="hidden-overflow p-0" fluid>
    <Menu />
  </Container>
);
